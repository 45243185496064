import { Box, Button, Container, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

interface FeaturesProps {
  setFormIsOpen: (value: boolean) => void;
}

const Features = ({ setFormIsOpen }: FeaturesProps) => {
  const mobilePhone = useMediaQuery('(max-width: 600px)')

  return <>
    <Stack sx={{ backgroundColor: theme => theme.palette.background.default, mt: 2, pb: {xs: 1, md: 3}}}>
      <Container maxWidth="xl" sx={{ position: "relative", zIndex: 10 }}>
        <Typography fontSize={{ xs: "1.75rem", md: "3.25rem" }} mt={6} lineHeight={1.15} fontWeight="700" sx={{ textAlign: "center" }} gutterBottom>
          Что входит в пакет материалов?
        </Typography>

        <Grid container spacing={3} mt={{ xs: 3, md: 5 }} mb={7} >
          <BoxMaterialsPackage
            number="01"
            title="Детально <br /> прописанные <br /> стратегии торговли"
            img="/images/trader-files/mockups/01.png"
          />
          <BoxMaterialsPackage
            number="02"
            title={mobilePhone ? "Список <br /> ресурсов, <br /> собранный  <br /> ТОПовыми <br /> трейдерами" : "Список ресурсов,  <br /> собранный  <br /> ТОПовыми трейдерами"}
            img="/images/trader-files/mockups/02.png"
          />
          <BoxMaterialsPackage
            number="03"
            title="Чек-листы  <br /> для входа  <br /> в позицию"
            img="/images/trader-files/mockups/03.png"
          />
          <BoxMaterialsPackage
            number="04"
            title="Лучшее время  <br /> для торговли, <br /> основанное на  <br /> сессиях"
            img="/images/trader-files/mockups/04.png"
          />
          <BoxMaterialsPackage
            number="05"
            title={mobilePhone ? "Глоссарий <br /> с понятными <br /> примерами <br /> ситуаций" : "Глоссарий <br /> с понятными  <br /> примерами ситуаций"}
            img="/images/trader-files/mockups/05.png"
          />
          <BoxMaterialsPackage
            number="06"
            title="Таблица <br /> зависимости <br /> альткоинов <br /> от доминации BTC"
            img="/images/trader-files/mockups/06.png"
          />
          <BoxMaterialsPackage
            number="07"
            title="Фигуры <br /> технического <br /> анализа"
            img="/images/trader-files/mockups/07.png"
          />
          <BoxMaterialsPackage
            number="08"
            title="Оптимальное <br /> 'железо' для <br /> скальпинга + <br /> готовая сборка"
            img="/images/trader-files/mockups/08.png"
          />
          <BoxMaterialsPackage
            number="09"
            title="Чек-листы <br /> для подготовки <br /> к торговой сессии"
            img="/images/trader-files/mockups/09.png"
          />
          <BoxMaterialsPackage
            number="10"
            title="Пример ведения <br /> плановой <br /> доходности в Excel"
            img="/images/trader-files/mockups/10.png"
          />
          <BoxMaterialsPackage
            number="11"
            title={mobilePhone ? "Зависимость <br /> торговли от <br /> темперамента <br />  + тест" : "Зависимость <br /> торговли <br /> от темперамента + <br /> тест"}
            img="/images/trader-files/mockups/11.png"
          />
          <BoxMaterialsPackage
            number="12"
            title={mobilePhone ? "Трекер <br /> привычек <br /> для выработки <br /> дисциплины" : "Трекер привычек <br /> для выработки <br /> дисциплины"}
            img="/images/trader-files/mockups/12.png"
          />
          <BoxMaterialsPackage
            number="13"
            title="Топ-6 книг, <br /> которые должен <br /> изучить каждый <br /> трейдер"
            img="/images/trader-files/mockups/13.png"
          />

          <Grid item xs={12} mt={{ xs: 3, md: 7 }} display="flex" justifyContent="center" >
            <Button onClick={() => {
              //@ts-ignore
              ym(96414864, 'reachGoal', 'button_form')
              setFormIsOpen(true)
            }}  
            variant="contained" sx={{ textTransform: "none", borderRadius: 6, px: 5, fontSize: { xs: 14, md: 18 }, height: { xs: 42, md: 65 } }}>Забрать файлы бесплатно</Button>
          </Grid>
        </Grid>
      </Container>
    </Stack>
  </>
}

interface BoxMaterialsPackageProps {
  number: string;
  title: string;
  img: string;
  maxWidth?: string | { xs: string, md: string };
}

const BoxMaterialsPackage = ({ number, title, img, maxWidth }: BoxMaterialsPackageProps) => {
  return <Grid item xs={12} sm={6}>
    <Box position="relative" height={{ xs: 240, md: 380 }} borderRadius={"32px"} p={2} pl={3} sx={{ background: "#f6f6f6"}}>
      <Box position="absolute" sx={{ "& img": { width: { xs: 210, md: 363 }, height: { xs: 220, md: 317 } } }} right={0} top={10}>
        <img style={{borderRadius: "5%"}} src={img} alt="" />
      </Box>
      <Stack height={"100%"} justifyContent="space-between" maxWidth={maxWidth ? maxWidth : "100%"} >
        <Typography fontSize={{ xs: 62, md: 114 }} lineHeight={"90px"} fontWeight={100} >{number}</Typography>
        <Typography
          fontSize={{ xs: 14, md: 28 }}
          lineHeight={{ xs: "19px", md: "32px" }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Stack>
    </Box>
  </Grid>;
};


export default Features;
